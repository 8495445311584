import {
  PE,
  PPC,
  SPP,
  TEACHER_AUTHORED,
  VIDEO,
} from 'chameleon/src/components/assignments/constants';
import {
  MQ,
  PPC as PPC_RESOURCE,
  PE as PE_RESOURCE,
  SPP as SPP_RESOURCE,
} from 'chameleon/src/constants/subjects';
import {
  isResourceVisibilityOverriddenByFF,
  isSubjectHasResource,
} from 'chameleon/src/utility/subjectResources';

export const validateTabType = ({
  subjectId,
  tabId,
  isStudent,
  afamEp27FullLaunchFF,
  afamEp26FullLaunchP2FF,
  afamEp26FullLaunchP1FF,
}) => {
  if (tabId === PPC) {
    return (
      isSubjectHasResource(subjectId, PPC_RESOURCE) ||
      isResourceVisibilityOverriddenByFF(subjectId, PPC_RESOURCE, isStudent, {
        afam_ep27_full_launch: afamEp27FullLaunchFF,
      })
    );
  }
  if (tabId === TEACHER_AUTHORED) {
    return (
      isSubjectHasResource(subjectId, MQ) ||
      isResourceVisibilityOverriddenByFF(subjectId, MQ, isStudent, {
        afam_ep27_full_launch: afamEp27FullLaunchFF,
      })
    );
  }
  if (tabId === PE) {
    return (
      isSubjectHasResource(subjectId, PE_RESOURCE) ||
      isResourceVisibilityOverriddenByFF(subjectId, PE_RESOURCE, isStudent, {
        afam_ep26_full_launch_p2: afamEp26FullLaunchP2FF,
      })
    );
  }
  if (tabId === SPP) {
    return isSubjectHasResource(subjectId, SPP_RESOURCE);
  }
  if (tabId === VIDEO) {
    return (
      isSubjectHasResource(subjectId, VIDEO) ||
      isResourceVisibilityOverriddenByFF(subjectId, VIDEO, isStudent, {
        afam_ep26_full_launch_p1: afamEp26FullLaunchP1FF,
      })
    );
  }

  return true;
};
