import { createTheme, adaptV4Theme } from '@mui/material/styles';
import tabs from 'legacy/src/styles/utility/OpTabs.module.scss';
import { tailwindConfig } from 'chameleon/ui-stack/config/tailwind-css';

const {
  theme: { colors },
} = tailwindConfig;

const textOverflow = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  display: 'inline-block',
};

export const apMuiTheme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: '#0076c4',
      },
      secondary: {
        main: '#1e1e1e',
      },
    },

    overrides: {
      MuiButtonBase: {
        root: {
          '&.Mui-focusVisible': {
            boxShadow: tabs.focus,
          },
        },
      },

      MuiTabs: {
        root: {
          minHeight: tabs.minHeight,
          marginBottom: tabs.marginBottom,
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
        },
        indicator: {
          display: 'none',
        },
        scrollButtons: {
          width: tabs.scrollButtonsWidth,
        },
        flexContainer: {
          borderBottom: tabs.border,
        },
        scrollable: {
          top: 2,
        },
        fixed: {
          top: 2,
        },
        centered: {
          top: 2,
        },
      },

      MuiTab: {
        root: {
          border: '1px solid transparent',
          minHeight: tabs.minHeight,
          maxWidth: tabs.tabWidthLg,
          padding: tabs.padding,
          lineHeight: tabs.lineHeight,
          ...textOverflow,
        },
        textColorPrimary: {
          color: tabs.colorFade,
          fontWeight: tabs.fontWeight,
          borderBottom: '5px solid transparent !important',
          '&.Mui-selected': {
            color: 'white',
            borderBottom: '5px solid white !important',
            // borderBottom: tabs.tabSelectedBorderBottom,
          },
          '&.Mui-selected .Mui-wrapper': {
            // borderBottom: tabs.border,
            // borderBottom: '3px solid red',
          },
        },
      },
      MuiTabScrollButton: {
        root: {
          color: colors.white,
          borderBottom: '1px solid transparent !important',
          marginBottom: '2px',
        },
      },

      MuiCheckbox: {
        root: {
          color: colors['gray-900'],
          padding: '0',
          '&.Mui-checked': {
            color: colors['gray-900'],
          },
        },
      },
      MuiInputBase: {
        input: {
          alignItems: 'center',
          backgroundColor: colors.white,
          border: `1px solid ${colors['gray-900']}`,
          borderRadius: 0,
          display: 'flex',
          fontSize: '1.5rem',
          padding: '10px 50px 10px 15px',
          '&:focus': {
            background: colors.white,
          },
        },
      },
      MuiSelect: {
        selectMenu: {
          '&:focus': {
            backgroundColor: colors.white,
            boxShadow: `0 0 3px 3px ${colors['blue-400']}`,
          },
        },
      },
    },

    // non-standard override for MuiInputBase
    MuiInputBase: {
      placeholderInput: {
        alignItems: 'center',
        backgroundColor: colors.white,
        border: `1px solid ${colors['gray-900']}`,
        color: colors['gray-600'],
        display: 'flex',
        fontSize: '1.5rem',
        padding: '10px 50px 10px 15px',
        '&:focus': {
          background: colors.white,
        },
      },
    },

    // non-standard override for MuiSelect
    MuiSelect: {
      paper: {
        border: `1px solid ${colors['gray-900']}`,
      },
      menuList: {
        paddingBottom: 0,
        paddingTop: 0,
      },
      menuItem: {
        borderTop: `1px solid ${colors['gray-400']}`,
        color: colors['blue-700'],
        fontSize: '1.5rem',
      },
      menuItemPlaceholder: {
        display: 'none',
      },
      menuItemDisabled: {
        color: colors['gray-900'],
      },
      menuItemSelected: {
        color: colors['gray-900'],
      },
    },

    typography: {
      fontSize: 16,
      htmlFontSize: 10,
      useNextVariants: true,
      button: {
        textTransform: 'none',
        letterSpacing: 'normal',
      },
    },

    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
  }),
);
