import {
  RESTRICTIONS_BY_USER_TYPE_AND_FF,
  RESTRICTIONS_BY_SUBJECT,
  ALL_SUBJECTS_LABEL,
  MY_ASSIGNMENTS,
  REPORTS,
  REPORTS_PROGRESS_CHECKS,
  CS_PERFORMANCE,
  QB,
} from 'chameleon/src/constants/subjects';

export const isResourceVisibilityOverriddenByFF = (
  subjectId,
  resource,
  isStudent,
  featureFlag = {},
) => {
  const userType = isStudent ? 'student' : 'teacher';
  const [featureFlagKey, featureFlagValue] =
    Object.entries(featureFlag)?.[0] || [];
  const ff = featureFlagValue ? featureFlagKey : null;

  const subjectsByResByFfByUserType =
    RESTRICTIONS_BY_USER_TYPE_AND_FF?.[resource]?.[ff]?.[userType];

  const configExists = Array.isArray(subjectsByResByFfByUserType);
  const isSubjectRestricted = (subjectsByResByFfByUserType || []).includes(
    +subjectId,
  );

  return configExists && !isSubjectRestricted;
};

export const isSubjectHasResource = (subjectId, resource) =>
  !(
    RESTRICTIONS_BY_SUBJECT[resource] &&
    Array.isArray(RESTRICTIONS_BY_SUBJECT[resource]) &&
    (RESTRICTIONS_BY_SUBJECT[resource].includes(+subjectId) ||
      RESTRICTIONS_BY_SUBJECT[resource].includes(ALL_SUBJECTS_LABEL))
  );

export const isMyAssignmentsVisible = ({
  subjectId,
  isStudent,
  afamEp27FullLaunchFF,
  afamEp26FullLaunchP1FF,
  afamEp26FullLaunchP2FF,
}) =>
  isSubjectHasResource(subjectId, MY_ASSIGNMENTS) ||
  isResourceVisibilityOverriddenByFF(subjectId, MY_ASSIGNMENTS, isStudent, {
    afam_ep26_full_launch_p1: afamEp26FullLaunchP1FF,
  }) ||
  isResourceVisibilityOverriddenByFF(subjectId, MY_ASSIGNMENTS, isStudent, {
    afam_ep26_full_launch_p2: afamEp26FullLaunchP2FF,
  }) ||
  isResourceVisibilityOverriddenByFF(subjectId, MY_ASSIGNMENTS, isStudent, {
    afam_ep27_full_launch: afamEp27FullLaunchFF,
  });

export const isStudentReportsVisible = ({
  subjectId,
  isStudent,
  afamEp26FullLaunchP2FF,
  afamEp27FullLaunchFF,
}) =>
  isSubjectHasResource(subjectId, REPORTS) ||
  isResourceVisibilityOverriddenByFF(subjectId, REPORTS, isStudent, {
    afam_ep26_full_launch_p2: afamEp26FullLaunchP2FF,
  }) ||
  isResourceVisibilityOverriddenByFF(subjectId, REPORTS, isStudent, {
    afam_ep27_full_launch: afamEp27FullLaunchFF,
  });

export const isReportsProgressCheckVisible = ({
  subjectId,
  isStudent,
  afamEp26FullLaunchP2FF,
  afamEp27FullLaunchFF,
}) =>
  isSubjectHasResource(subjectId, REPORTS_PROGRESS_CHECKS) ||
  isResourceVisibilityOverriddenByFF(
    subjectId,
    REPORTS_PROGRESS_CHECKS,
    isStudent,
    {
      afam_ep26_full_launch_p2: afamEp26FullLaunchP2FF,
    },
  ) ||
  isResourceVisibilityOverriddenByFF(
    subjectId,
    REPORTS_PROGRESS_CHECKS,
    isStudent,
    {
      afam_ep27_full_launch: afamEp27FullLaunchFF,
    },
  );

export const isMasteryReportsVisible = ({
  subjectId,
  isStudent,
  afamEp26FullLaunchP2FF,
  masteryReportFF,
}) =>
  isResourceVisibilityOverriddenByFF(subjectId, CS_PERFORMANCE, isStudent, {
    mastery_report: masteryReportFF,
  }) ||
  isResourceVisibilityOverriddenByFF(subjectId, CS_PERFORMANCE, isStudent, {
    afam_ep26_full_launch_p2: afamEp26FullLaunchP2FF,
  });

export const isQuestionBankVisible = ({
  subjectId,
  isStudent,
  afamEp27FullLaunchFF,
}) =>
  isSubjectHasResource(subjectId, QB) ||
  isResourceVisibilityOverriddenByFF(subjectId, QB, isStudent, {
    afam_ep27_full_launch: afamEp27FullLaunchFF,
  });

export const isTeacherReportsVisible = ({
  subjectId,
  isStudent,
  afamEp26FullLaunchP2FF,
  afamEp27FullLaunchFF,
  afamEp26FullLaunchP1FF,
}) =>
  isSubjectHasResource(subjectId, REPORTS) ||
  isResourceVisibilityOverriddenByFF(subjectId, REPORTS, isStudent, {
    afam_ep26_full_launch_p1: afamEp26FullLaunchP1FF,
  }) ||
  isResourceVisibilityOverriddenByFF(subjectId, REPORTS, isStudent, {
    afam_ep26_full_launch_p2: afamEp26FullLaunchP2FF,
  }) ||
  isResourceVisibilityOverriddenByFF(subjectId, REPORTS, isStudent, {
    afam_ep27_full_launch: afamEp27FullLaunchFF,
  });
