import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import uniqBy from 'lodash/uniqBy';
import { MixedLink } from '../../index';
import PopOver from 'chameleon/ui-stack/ui/base/popover';
import ButtonWithIcon from 'chameleon/ui-stack/ui/buttons/button-with-icon';
import Icon from 'chameleon/ui-stack/ui/base/icon';
import { TYPES } from 'legacy/src/utility/Filters/utils';
import {
  ACTIVE_RESOURCES_ID,
  ALL_RESOURCES_ID,
  ASSIGNED_RESOURCES_ID,
  COMPLETED_RESOURCES_ID,
  MASTERY_REPORT_ID,
  QB_ALL_QUESTIONS_ID,
  QB_AUTHORING_QUESTION_ID,
  REPORTS_ALL_ASSIGNMENTS_ID,
  SHARED_QUIZZES_ID,
  STUDENT_PROGRESS_DASH_LABEL_ID,
  TEACHER_PROGRESS_DASH_LABEL_ID,
  UPCOMING_RESOURCES_ID,
} from 'chameleon/src/components/SidebarWrapperTwoLevels/constants';
import {
  isMasteryReportsVisible,
  isMyAssignmentsVisible,
  isQuestionBankVisible,
  isReportsProgressCheckVisible,
  isStudentReportsVisible,
  isSubjectHasResource,
  isTeacherReportsVisible,
} from 'chameleon/src/utility/subjectResources';
import {
  CS_PERFORMANCE,
  isPreApSubject,
  PPC,
  QB,
  REPORTS,
} from 'chameleon/src/constants/subjects';
import { validateTabType } from './utils';
import * as types from 'legacy/src/constants/types';

const SubjectSelector = (props) => {
  const {
    redirectPath = 'home',
    fymSubjects = [],
    onSubjectClick,
    userData,
    subject,
    location,
    afamEp26FullLaunchP1FF,
    afamEp26FullLaunchP2FF,
    afamEp27FullLaunchFF,
    masteryReportFF,
  } = props;
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState({});

  useEffect(() => {
    const {
      isStudent,
      isTeacher,
      isAdmin,
      isCoordinator,
      teacherSubjects,
      studentSubjects,
      adminSubjects,
    } = userData;
    const isAdminCoordinator = isAdmin || isCoordinator;
    if (isStudent) {
      setSubjects(studentSubjects);
    } else if (isTeacher) {
      setSubjects(teacherSubjects);
    } else if (isAdminCoordinator) {
      setSubjects(
        uniqBy(
          adminSubjects.map((item) => {
            const { masterSubjectId, name, subjectProgram } = item;
            return {
              id: masterSubjectId.toString(),
              name,
              subjectProgram,
            };
          }),
          'id',
        ),
      );
    }
  }, [subject]);

  useEffect(() => {
    if (subject && subjects.length) {
      const currentSubject = subjects.find(({ id }) => id === subject);
      // the FilterControls component will try to re-load details about the last group you were looking at on a few
      // different pages, this data is stored in local storage, but is persisting when changed in subject.
      window.localStorage.removeItem(TYPES.localStorageGroupFilterKey);
      setSelectedSubject(currentSubject);
    }
  }, [subject, subjects]);

  const { name, id: selectedSubjectId } = selectedSubject || {};

  if ((subjects || []).length === 1) {
    return <span className="px-4.6 h-12.6 flex items-center">{name}</span>;
  }

  const getCurrentPathId = () => {
    const currentPathId = document
      .querySelector('a[aria-current="page"][data-nav-id]')
      ?.getAttribute('data-nav-id');

    return currentPathId;
  };

  const getSubjectSwitchPath = (id, isFymSubject, isFutureSubjectPreAp) => {
    const currentPathId = getCurrentPathId();
    let persistedPath = 'home';

    const MAP_ID_TO_CONDITION = {
      // STUDENT -- ASSIGNMENTS
      [ACTIVE_RESOURCES_ID]: () =>
        isSubjectHasResource(id, ACTIVE_RESOURCES_ID) &&
        isMyAssignmentsVisible({
          subjectId: id,
          isStudent: userData.isStudent,
          afamEp27FullLaunchFF,
          afamEp26FullLaunchP1FF,
          afamEp26FullLaunchP2FF,
        }),
      [UPCOMING_RESOURCES_ID]: () =>
        isSubjectHasResource(id, UPCOMING_RESOURCES_ID) &&
        isMyAssignmentsVisible({
          subjectId: id,
          isStudent: userData.isStudent,
          afamEp27FullLaunchFF,
          afamEp26FullLaunchP1FF,
          afamEp26FullLaunchP2FF,
        }),
      [COMPLETED_RESOURCES_ID]: () =>
        isSubjectHasResource(id, COMPLETED_RESOURCES_ID) &&
        isMyAssignmentsVisible({
          subjectId: id,
          isStudent: userData.isStudent,
          afamEp27FullLaunchFF,
          afamEp26FullLaunchP1FF,
          afamEp26FullLaunchP2FF,
        }),
      // STUDENT -- REPORTS
      [STUDENT_PROGRESS_DASH_LABEL_ID]: () =>
        isStudentReportsVisible({
          subjectId: id,
          student: userData.isStudent,
          afamEp27FullLaunchFF,
          afamEp26FullLaunchP2FF,
        }) &&
        isReportsProgressCheckVisible({
          subjectId: id,
          isStudent: userData.isStudent,
          afamEp26FullLaunchP2FF,
          afamEp27FullLaunchFF,
        }),
      [MASTERY_REPORT_ID]: () =>
        isMasteryReportsVisible({
          subjectId: id,
          isStudent: userData.isStudent,
          afamEp26FullLaunchP2FF,
          masteryReportFF,
        }),
      // TEACHER -- QB
      [QB_ALL_QUESTIONS_ID]: () =>
        isQuestionBankVisible({
          subjectId: id,
          isStudent: userData.isStudent,
          afamEp27FullLaunchFF,
        }),
      [QB_AUTHORING_QUESTION_ID]: () =>
        isQuestionBankVisible({
          subjectId: id,
          isStudent: userData.isStudent,
          afamEp27FullLaunchFF,
        }),
      // TEACHER -- ASSIGNMENTS
      [ASSIGNED_RESOURCES_ID]: () =>
        isSubjectHasResource(id, ASSIGNED_RESOURCES_ID),
      [ALL_RESOURCES_ID]: () => isSubjectHasResource(id, ALL_RESOURCES_ID),
      [SHARED_QUIZZES_ID]: () =>
        isSubjectHasResource(id, SHARED_QUIZZES_ID) &&
        isSubjectHasResource(id, QB),
      // TEACHER -- REPORTS
      [REPORTS_ALL_ASSIGNMENTS_ID]: () =>
        isTeacherReportsVisible({
          subjectId: id,
          isStudent: userData.isStudent,
          afamEp26FullLaunchP2FF,
          afamEp27FullLaunchFF,
          afamEp26FullLaunchP1FF,
        }),
      [TEACHER_PROGRESS_DASH_LABEL_ID]: () =>
        isSubjectHasResource(id, REPORTS) && isSubjectHasResource(id, PPC),
      [MASTERY_REPORT_ID]: () =>
        isSubjectHasResource(id, CS_PERFORMANCE) ||
        isMasteryReportsVisible({
          subjectId: id,
          isStudent: userData.isStudent,
          afamEp26FullLaunchP2FF,
          masteryReportFF,
        }),
    };

    if (!(isFutureSubjectPreAp || isPreApSubject(id))) {
      if (
        MAP_ID_TO_CONDITION[currentPathId] &&
        MAP_ID_TO_CONDITION[currentPathId]()
      ) {
        let fullPath = location.pathname;
        let pathParams = location.search;

        // we dont want to persist params in this page
        if (currentPathId === QB_ALL_QUESTIONS_ID) {
          pathParams = '';
        }

        const typeParam = location.query?.type;
        if (typeParam) {
          const newSubjectHasType = validateTabType({
            subjectId: id,
            tabId: typeParam,
            isStudent: userData.isStudent,
            afamEp27FullLaunchFF,
            afamEp26FullLaunchP2FF,
            afamEp26FullLaunchP1FF,
          });

          if (newSubjectHasType) {
            fullPath = fullPath.concat(pathParams);
          } else {
            // if the subject doesnt support the tab type,
            // we remove it, to use the default one
            fullPath = fullPath
              .concat(pathParams)
              .replace(`&type=${typeParam}`, '');
          }
        } else {
          fullPath = fullPath.concat(pathParams);
        }

        const subjectPath = `/${subject}/`;
        persistedPath = fullPath.replace(subjectPath, '');
      }
    }

    return isFymSubject
      ? `/${id}/${redirectPath}`
      : `${FYM_FRONTEND_URL}/${id}/${persistedPath}`;
  };

  return (
    <div className="flex max-w-full">
      <h3
        tabindex="-1"
        className="w-full text-center text-gray-900 leading-[30px] mr-4 whitespace-nowrap overflow-hidden text-ellipsis font-medium m-0 inline-block break-words ..."
      >
        {name}
      </h3>
      <PopOver
        alwaysClose
        position="start"
        isNarrow
        trigger={(buttonProps, isOpen, ref) => (
          <ButtonWithIcon
            isSecondary
            iconAccessibility={{
              ariaHidden: true,
            }}
            iconOrientation="right"
            accessibility={{
              'aria-label': `Subject selector - ${name}`,
            }}
            ref={ref}
            icon="keyboard_arrow_down"
            {...buttonProps}
          ></ButtonWithIcon>
        )}
      >
        <div>
          {subjects.map((futureSubject, index) => {
            const { id, subjectProgram } = futureSubject;
            const isFymSubject = find(fymSubjects, {
              id,
            });

            let extraMixedLinkProps = {};
            if (onSubjectClick) {
              extraMixedLinkProps.onClick = (event) =>
                onSubjectClick(event, id);
            }

            const path = getSubjectSwitchPath(
              id,
              isFymSubject,
              subjectProgram === types.PreAP,
            );

            return (
              <div key={`${id}-${index}`}>
                <MixedLink
                  className="px-4.6 h-12.6 text-blue-700 hover:bg-gray-200 whitespace-nowrap flex items-center"
                  path={path}
                  {...extraMixedLinkProps}
                >
                  {futureSubject.name}
                  {id === selectedSubjectId ? (
                    <Icon
                      id="checkmark"
                      className="text-gray-600 ml-auto"
                      accessibility={{
                        title: 'Selected',
                      }}
                    />
                  ) : null}
                </MixedLink>
              </div>
            );
          })}
        </div>
      </PopOver>
    </div>
  );
};

SubjectSelector.displayName = 'SubjectSelector';

SubjectSelector.propTypes = {
  redirectPath: PropTypes.string,
  fymFrontEndUrl: PropTypes.string,
  fymSubjects: PropTypes.array,
  onSubjectClick: PropTypes.func,
  userData: PropTypes.object.isRequired,
  subject: PropTypes.string.isRequired,
};

SubjectSelector.defaultProps = {
  onSubjectClick: null,
};

export default SubjectSelector;
