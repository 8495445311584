import { PDF_STATUS } from 'chameleon/src/constants/assessmentStatus';

const ASSIGNED = 'assigned';
const COMPLETED = 'completed';
export const ALL_RESOURCES = 'all-resources';
export const ASSIGNED_RESOURCES = 'assigned-resources';
export const UNASSIGNED = 'unassigned';
const RTS = 'ready-to-score';
const UPCOMING = 'upcoming';
const ALL_ASSIGNED = 'all-assigned';
export const COURSE_GUIDE_LABEL = 'Course Guide';
export const REPORTS_LABEL = 'Reports';
export const PROFESSIONAL_LEARNING_LABEL = 'Professional Learning';
export const PROGRESS_DASH_LABEL = 'Progress Checks';
export const MASTERY_REPORT_LABEL = 'Content & Skills Performance';
export const LEARNING_OBJECTIVES_REPORT_LABEL =
  'Learning Objectives & Standards';
export const MASTERY_REPORT_ID = '34';
export const LEARNING_CHECKPOINT_LABEL = 'Learning Checkpoints';
export const PERFORMANCE_TASK_LABEL = 'Performance Tasks';
export const ASSIGNED_RESOURCES_ID = '20';
export const ALL_RESOURCES_ID = '24';
export const ACTIVE_RESOURCES_ID = '25';
export const UPCOMING_RESOURCES_ID = '26';
export const COMPLETED_RESOURCES_ID = '27';
export const STUDENT_PROGRESS_DASH_LABEL_ID = '31';
export const TEACHER_PROGRESS_DASH_LABEL_ID = '32';
export const QUESTION_BANK_ID = 'question-bank';
export const QB_ALL_QUESTIONS_ID = 'all-questions';
export const QB_AUTHORING_QUESTION_ID = 'authoring-question';
export const SHARED_QUIZZES_ID = 'shared-quizzes';
export const REPORTS_ALL_ASSIGNMENTS_ID = 'reports-all-assignments';

export const PROFESSIONALS_ASSIGNMENTS_STATUSES = [
  {
    id: ASSIGNED_RESOURCES_ID,
    label: 'Assigned Resources',
    pageTitle: 'Assigned Resources',
    status: ALL_ASSIGNED,
    aliases: [ASSIGNED, RTS, COMPLETED],
  },
  {
    id: ALL_RESOURCES_ID,
    label: 'All Resources',
    pageTitle: 'All Resources',
    status: ALL_RESOURCES,
    aliases: [
      ASSIGNED_RESOURCES,
      ASSIGNED,
      UNASSIGNED,
      PDF_STATUS.ALWAYS_VISIBLE,
      PDF_STATUS.SHARED,
      PDF_STATUS.NOT_SHARED,
    ],
  },
];

export const ALL_RESOURCES_STATUSES = [
  ALL_RESOURCES,
  ASSIGNED_RESOURCES,
  ASSIGNED,
  UNASSIGNED,
  PDF_STATUS.ALWAYS_VISIBLE,
  PDF_STATUS.SHARED,
  PDF_STATUS.NOT_SHARED,
];

export const STUDENT_ASSIGNMENTS_STATUSES = [
  {
    id: ACTIVE_RESOURCES_ID,
    label: 'Active',
    pageTitle: 'Active',
    status: ASSIGNED,
    others: [ASSIGNED, COMPLETED, UPCOMING],
  },
  {
    id: UPCOMING_RESOURCES_ID,
    label: 'Upcoming',
    pageTitle: 'Upcoming',
    status: UPCOMING,
  },
  {
    id: COMPLETED_RESOURCES_ID,
    label: 'Completed',
    pageTitle: 'Completed',
    status: COMPLETED,
  },
];

export const getAssignmentStatuses = (isProfessional, isStudent) =>
  isProfessional
    ? PROFESSIONALS_ASSIGNMENTS_STATUSES
    : isStudent
      ? STUDENT_ASSIGNMENTS_STATUSES
      : [];
